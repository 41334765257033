import axios from "axios";
// import downloadBlob from "../common/utils/download-blob";
import toQueryString from "../common/utils/to-query-string";

// const getTrails = (query) => axios.get(toQueryString("api/v1/trails?", query));
const getAnalytics = (query) => axios.get(toQueryString("api/v1/analytics/messages?", query));

const getAnalyticsById = (id, query) => axios.get(toQueryString(`api/v1/analytics/messages/${id}?`, query));
// const exportTrails = () =>
//   axios.get("/api/v1/trails?content=1").then((resp) => {
//     const { content, filename } = resp.data;
//     downloadBlob(content, filename);
//   });

// const getSubjectTrails = (query) =>
//   axios.get(toQueryString("api/v1/subject-trails?", query));

// const exportSubjectTrails = () =>
//   axios.get("/api/v1/subject-trails?content=1").then((resp) => {
//     const { content, filename } = resp.data;
//     downloadBlob(content, filename);
//   });

// const getScenarioTrails = (query) =>
//   axios.get(toQueryString("api/v1/scenario-trails?", query));

// const exportScenarioTrails = () =>
//   axios.get("/api/v1/scenario-trails?content=1").then((resp) => {
//     const { content, filename } = resp.data;
//     downloadBlob(content, filename);
//   });

const ApiAnalytics = {
  getAnalytics,
  getAnalyticsById
  // exportTrails,
  // getSubjectTrails,
  // exportSubjectTrails,
  // getScenarioTrails,
  // exportScenarioTrails
};

export default ApiAnalytics;
