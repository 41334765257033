import React from "react";
import "./statistic-chart.css";

const HorizontalBarChart = ({ label, value, count }) => {
  return (
    <div className="horizontal-bar-chart">
      <div className="horizontal-bar-chart__label-container">
        <span className="horizontal-bar-chart__label">{label}</span>
        <span className="horizontal-bar-chart__percentage">
          {value}%{count ? ` (${count})` : ''}
        </span>
      </div>
      <div className="horizontal-bar-chart__bar-container">
        <div
          className="horizontal-bar-chart__bar-filled"
          style={{ width: `${value}%` }}
        ></div>
      </div>
    </div>
  );
};

const StatisticChart = ({ data = []}) => {
  return (
     <div className="statistic-chart">
      <div className="statistic-chart__bars">
        {data.map((item, index) => (
          <HorizontalBarChart
            key={index}
            label={item.label}
            value={item.value}
            count={item.count}
          />
        ))}
      </div>
    </div>
  )
};

export default StatisticChart;