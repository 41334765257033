// import { useEffect, useState, useCallback, useMemo } from "react";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAnalytics,
  fetchAnalytics,
  selecthasMoreAnalytics,
  selectAnalytics,
  selectAnalyticsLoading,
} from "../../../../store/slices/message-analytics";
import AuditAnalytcisHeader from "../audit-analytics-header";
import "../../styles.css";
import InfinityTable from "../../../../components/infinity-table/infinity-table";
import moment from "moment";
import { Body } from "../../../../components/main-body/body";
import classNames from "classnames";
import "../../../../components/main-body/body.css";
import { Progress } from "antd";
import AnalyticsImages from "../../../../assets/analytics-images/index";
import ApiScenariosManagement from "../../../../api/scenarios-management";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
// import debounce from "../../../../common/utils/debounce";
import AnalyticsCard from "./analytics-card/analytics-card";
import { useNavigate, useLocation } from "react-router-dom";

const MessagesAnalyticsTable = ({ navigation }) => {
  const analytics = useSelector(selectAnalytics);
  const hasMore = useSelector(selecthasMoreAnalytics);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const isLoading = useSelector(selectAnalyticsLoading);
  const [filters, setFilters] = useState({});
  const [scenariosList, setScenarioList] = useState([]);
  const [subjectsList, setSubjectsList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [selectedAnalytics, setSelectedAnalytics] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const handleSelectAnalytics = (message) => {
    handleNavigate(`/reports/analytics/scenarios/${message.id}`);
    setSelectedAnalytics(message);
    setOpen(true);
  };

  // const openTaskDrawer = () => {
  //   handleNavigate(`/reports/analytics/messages/${selectedAnalytics.id}`);
  //   setOpen(true);
  // };

  const closeTaskDrawer = () => {
    handleNavigate(`/reports/analytics/scenarios`);
    setOpen(false);
    setSelectedAnalytics({});
  };

  const fetchData = (currentPage = page, query = searchValue, currentFilters = filters, reset = false) => {
    if (reset) {
      dispatch(clearAnalytics()); // Очищення даних лише під час пошуку або фільтрації
    }

    dispatch(
      fetchAnalytics({
        query,
        page: currentPage,
        ...currentFilters,
        createdAtEnd: currentFilters.period?.[currentFilters.period.length - 1]?.end,
        createdAtStart: currentFilters.period?.[currentFilters.period.length - 1]?.start,
      })
    );
    setPage(currentPage + 1);
  };

  // const debouncedGetAnalytics = useCallback(
  //   debounce((currentPage, query, currentFilters) => {
  //     fetchData(currentPage, query, currentFilters);
  //   }, 400),
  //   [filters, page]
  // );

  const handleUpdateSearchValue = (value) => {
    setSearchValue(value);
    setPage(1);
    fetchData(1, value, filters, true);
  };

  const fetchScenarioList = () => {
    ApiScenariosManagement.getScenariosSimple().then((r) => {
      setScenarioList(
        r.data.scenarios.map((s) => ({
          id: s.id,
          name: s.title,
        }))
      );
    });
  };

  const fetchSubjectList = () => {
    ApiSubjectsManagement.getSubjectsSimple().then((r) => {
      setSubjectsList(
        r.data.subjects.map((s) => ({
          id: s.id,
          name: s.displayId,
        }))
      );
    });
  };

  const updateFilterValue = (filter) => {
    setFilters(filter);
  };

  const getIcon = (questionType) => {
    switch (questionType) {
      case "message:multi":
        return AnalyticsImages.checkBorder;
      case "message:single":
        return AnalyticsImages.radioChecked;
      case "message:date":
        return AnalyticsImages.date;
      case "message:picker":
        return AnalyticsImages.typeSelect;
      case "message:input":
        return AnalyticsImages.text;
      default:
        return AnalyticsImages.typeSelect;
    }
  };

  const getQuestionTypeLabel = (questionType) => {
    switch (questionType) {
      case "message:single":
        return "Single choice";
      case "message:multi":
        return "Multi choice";
      case "message:picker":
        return "Picker";
      case "message:date":
        return "Date";
      case "message:input":
        return "Input";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const messageId = pathParts[pathParts.length - 1];

    if (messageId && messageId !== "messages") {
      const foundMessage = analytics.find((message) => message.id === messageId);
      if (foundMessage) {
        setSelectedAnalytics(foundMessage);
        setOpen(true);
      }
    } else {
      setOpen(false);
      setSelectedAnalytics({});
    }
  }, [location.pathname, analytics]);

  useEffect(() => {
    fetchScenarioList();
    fetchSubjectList();
  }, []);

  useEffect(() => {
    dispatch(clearAnalytics());
    fetchData(1);
  }, [filters, dispatch]);

  const columns = [
    {
      title: t("analytics.table.message"),
      dataIndex: "title",
      render: (_, analytics) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div
            style={{
              flexShrink: 0,
              width: "40px",
              height: "40px",
              backgroundColor: "#EFEFEF",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={getIcon(analytics.questionType)}
              alt="icon"
              style={{ width: "20px", height: "20px" }}
            />
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>{analytics.title || "N/A"}</span>
            <div style={{ fontSize: "12px", color: "#888" }}>
              {getQuestionTypeLabel(analytics.questionType)}
            </div>
          </div>
        </div>
      ),
      key: "title",
      width: "40%"
    },
    {
      title: t("analytics.table.last_response"),
      dataIndex: "lastRespondedAt",
      render: (_, analytics) => (
        <span>
          {analytics.lastRespondedAt
            ? `${moment(analytics.lastRespondedAt)
                .utc()
                .format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A"}
        </span>
      ),
      key: "lastRespondedAt",
      width: "20%"
    },
    {
      title: t("analytics.table.scenario"),
      dataIndex: "scenarioTitle",
      render: (_, analytics) => <span>{analytics.scenarioTitle || "N/A"}</span>,
      key: "scenarioTitle",
      width: "15%"
    },
    {
      title: t("analytics.table.total"),
      dataIndex: "total",
      render: (_, analytics) => <span>{(analytics.totalCount + analytics.pendingCount) || 0}</span>,
      key: "total",
      width: "10%"
    },
    {
      title: t("analytics.table.completion_rate"),
      dataIndex: "completionRate",
      render: (_, analytics) => (
        <span style={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: "8px" }}>
          <Progress
            type="circle"
            percent={analytics.respondedPercentage || 0}
            width={25}
            strokeLinecap="butt"
            strokeWidth={25}
            strokeColor="#2D7FFA"
            trailColor="rgba(45, 127, 250, 0.2)"
            format={() => ""}
          />
          {analytics.respondedPercentage || 0}%
        </span>
      ),
      key: "completionRate",
      width: "15%"
    },
  ];

  const messageTypes = [
    { id: "message:single", name: "Single Choice" },
    { id: "message:multi", name: "Multi Choice" },
    { id: "message:picker", name: "Picker" },
    { id: "message:date", name: "Date" },
    { id: "message:input", name: "Input" },
  ];

  const filterItems = [
    { name: "Date peroid", id: "period", period: true },
    { name: "Message Type", id: "actionType", children: messageTypes },
    { name: "Scenario", id: "scenarioIds", children: scenariosList },
  ];

  const headerProps = useMemo(
    () => ({
      search: {
        value: searchValue,
        onChange: handleUpdateSearchValue,
      },
    }),
    [searchValue]
  );

  return (
    <Body disabledPaddings={true}>
      <AuditAnalytcisHeader
        t={t}
        filterItems={filterItems}
        updateFilterValue={updateFilterValue}
        header={headerProps}
      />
      <div className={classNames("lf-content")}>
        <InfinityTable
          data={analytics}
          fetchData={() => fetchData(page, searchValue, filters)}
          hasMore={hasMore}
          tableColumns={columns}
          isLoading={isLoading}
          before={navigation}
          onHandleRowClick={handleSelectAnalytics}
        />
      </div>
      <AnalyticsCard
        analytics={selectedAnalytics}
        isOpen={isOpen}
        close={closeTaskDrawer}
        messageTypes={messageTypes}
        subjects={subjectsList}
        initialPeriodFilter={filters.hasOwnProperty('period') ? { period: filters.period } : {} }
      />
    </Body>
  );
};

export default MessagesAnalyticsTable;
