import { createSlice } from "@reduxjs/toolkit";
import ApiAnalytics from "../../api/analytics";

export const AuditAnalyticsSlice = createSlice({
  name: "message-analytics",
  initialState: {
    analytics: [],
    hasMore: false,
    isLoading: false,
  },
  reducers: {
    setAnalytics: (state, action) => {
      const newAnalytics = action.payload.filter(
        (newItem) => !state.analytics.some((existingItem) => existingItem.id === newItem.id)
      );
      return {
        ...state,
        analytics: state.analytics ? [...state.analytics, ...newAnalytics] : newAnalytics,
        hasMore: action.payload?.length && action.payload?.length > 28,
        isLoading: false,
      };
    },
    resetAnalytics: (state) => ({
      ...state,
      analytics: [],
      hasMore: false,
      isLoading: false,
    }),
    setLoading: (state) => ({ ...state, isLoading: true }),
  },
});

export const { setAnalytics, resetAnalytics, setLoading } = AuditAnalyticsSlice.actions;

export const fetchAnalytics = (query) => (dispatch) => {
  dispatch(setLoading());
  ApiAnalytics.getAnalytics(query)
    .then((r) => {
      dispatch(setAnalytics(r.data.analytics));
    })
    .catch(() => {
      dispatch(resetAnalytics());
    });
};

export const clearAnalytics = () => (dispatch) => {
  dispatch(resetAnalytics());
};

export const selectAnalytics = (state) => state.messageAnalytics.analytics;
export const selecthasMoreAnalytics = (state) => state.messageAnalytics.hasMore;
export const selectAnalyticsLoading = (state) => state.messageAnalytics.isLoading;

export default AuditAnalyticsSlice.reducer;
