import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/user";
import usersManagement from "./slices/user_management";
import trails from "./slices/trails";
import subjectTrails from "./slices/subject-trails";
import scenarioTrails from "./slices/scenario-trails";
import messageAnalytics from "./slices/message-analytics";

const reducer = {
  user,
  usersManagement,
  trails,
  subjectTrails,
  scenarioTrails,
  messageAnalytics
};

export default configureStore({ reducer });
